
import { useEffect, useRef, forwardRef, useLayoutEffect } from "external/react"
import es from '~/node_modules/jodit/esm/langs/es.js'


const JoditEditor = forwardRef(
  ({ value, config, onChange, onInit, onBlur, tabIndex, name }, ref) => {

    const textArea = useRef(null);

    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === "function") {
          ref(textArea.current);
        } else {
          ref.current = textArea.current;
        }
      }
    }, [textArea])

    useEffect(() => {
      const blurHandler = value => {
        onBlur && onBlur(value, textArea.current);
      }

      const changeHandler = value => {
        onChange && onChange(value);
      }

      const element = textArea.current;
      const jodit = Jodit.make(element, config);

      onInit && onInit(jodit)

      textArea.current = jodit;
      textArea.current.value = value;

      textArea.current.events.on("blur", () =>
        blurHandler(textArea.current.value)
      )
      textArea.current.events.on("change", () =>
        changeHandler(textArea.current.value)
      )

      textArea.current.workplace.tabIndex = tabIndex || -1

      return (() => {
        jodit.destruct();
      })

    }, [])

    return <textarea ref={textArea} name={name}></textarea>
  }
)

export default JoditEditor
